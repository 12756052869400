import React, { Suspense } from "react";
import { useRoutes } from "react-router-dom";

// components
import Root from "./Root";

// lazy load all the views

// home
const Home = React.lazy(() => import("../pages/Home"));

// landings
const Saas = React.lazy(() => import("../pages/landings/Saas/Saas"));
const Saas2 = React.lazy(() => import("../pages/landings/Saas/Saas2"));
const product = React.lazy(() => import("../pages/landings/Saas/Saas"));

// pages

const Company = React.lazy(() => import("../pages/other/Company"));
const Contact = React.lazy(() => import("../pages/other/Contact"));
const Pricing = React.lazy(() => import("../pages/other/Pricing"));


// docs
const Introduction = React.lazy(() => import("../pages/docs/Introduction"));
const QuickStart = React.lazy(() => import("../pages/docs/QuickStart"));
const Customization = React.lazy(() => import("../pages/docs/Customization"));
const Routing = React.lazy(() => import("../pages/docs/Routing"));
const CodeSpliting = React.lazy(() => import("../pages/docs/CodeSpliting"));
const ChangeLog = React.lazy(() => import("../pages/docs/ChangeLog"));

const Colors = React.lazy(() => import("../pages/docs/Colors"));
const Typography = React.lazy(() => import("../pages/docs/Typography"));

const Bootstrap = React.lazy(() => import("../pages/docs/Bootstrap"));
const Custom = React.lazy(() => import("../pages/docs/Custom"));
const Plugins = React.lazy(() => import("../pages/docs/Plugins"));

const Navbars = React.lazy(() => import("../pages/docs/Navbars"));
const Heros = React.lazy(() => import("../pages/docs/Heros"));

const loading = () => <div className=""></div>;

type LoadComponentProps = {
  component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
  <Suspense fallback={loading()}>
    <Component />
  </Suspense>
);

const AllRoutes = () => {
  return useRoutes([
    {
      // root route
      path: "/",
      element: <Root />,
    },
    {
      // public routes
      path: "/",
      children: [
        
        {
          path: "docs",
          children: [
            {
              path: "introduction",
              element: <LoadComponent component={Introduction} />,
            },
            {
              path: "quick-start",
              element: <LoadComponent component={QuickStart} />,
            },
            {
              path: "customization",
              element: <LoadComponent component={Customization} />,
            },
            {
              path: "routing",
              element: <LoadComponent component={Routing} />,
            },
            {
              path: "code-spliting",
              element: <LoadComponent component={CodeSpliting} />,
            },
            {
              path: "change-log",
              element: <LoadComponent component={ChangeLog} />,
            },

            { path: "colors", element: <LoadComponent component={Colors} /> },
            {
              path: "typography",
              element: <LoadComponent component={Typography} />,
            },
            {
              path: "bootstrap",
              element: <LoadComponent component={Bootstrap} />,
            },
            { path: "custom", element: <LoadComponent component={Custom} /> },
            {
              path: "plugins",
              element: <LoadComponent component={Plugins} />,
            },
            {
              path: "navbars",
              element: <LoadComponent component={Navbars} />,
            },
            { path: "heros", element: <LoadComponent component={Heros} /> },
          ],
        },
        {
          path: "home",
          element: <LoadComponent component={Home} />,
        },
        {
          path: "product",
          element: <LoadComponent component={product} />,
        },
        {
          path: "landing",
          children: [
            { path: "saas", element: <LoadComponent component={Saas} /> },
            { path: "saas2", element: <LoadComponent component={Saas2} /> },
          ],
        },
        {
          path: "pages",
          children: [
            
            {
              path: "company",
              element: <LoadComponent component={Company} />,
            },
            {
              path: "contact",
              element: <LoadComponent component={Contact} />,
            },
            {
              path: "pricing",
              element: <LoadComponent component={Pricing} />,
            },
            
          ],
        },
      ],
    },
  ]);
};

export default AllRoutes;
